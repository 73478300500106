import Vue from "vue";
import App from "./App.vue";
import router from "./router";

Vue.config.productionTip = false;

console.log("process.env.NODE_ENV", process.env.NODE_ENV, process.env);


document.location.href = 'https://www.macartemode.fr'
  

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
